import {
    Button,
    Link
} from 'open-law-cms/schema'
import React, {
    FunctionComponent,
    useEffect,
    useState
} from 'react'

import Client from '@sanity/client'

export function getInternalLinkReference(routeId: string): Promise<string> {
    const query  = '*[_id == $id][0]'
    const params = {id: routeId}
    const client = new Client({
        dataset: 'production',
        projectId: '81k3cw4t'
    })
    return client.fetch(query, params).then((data) => data.slug.current)
}

interface SanityLinkProps {
    text: string
    node: Link | Button
    styles?: string
}


const SanityLink: FunctionComponent<SanityLinkProps> = (props) => {
    // console.log('Link props: ', props)
    const {text, node, textColour, styles}      = props
    const {external, internal, openInNewWindow} = node

    const [href, setHref] = useState<string>('/')

    useEffect(() => {
            if (node._type === 'button' && node?.url?.internal?._ref) {
                getInternalLinkReference(node.url.internal._ref).then((slug) => setHref(slug))
            } else {
                if (external && typeof external === 'string') {
                    setHref(external)
                } else if (internal && internal.slug && internal.slug.current) {
                    setHref(`/${internal.slug.current}`)
                } else if (internal && (internal?._ref || internal?.route?._ref)) {
                    getInternalLinkReference(internal?.route?._ref || internal._ref).then((slug) => setHref(slug))
                }
            }
        }, [node]
    )


    return href ? (
        <a className={`cursor-pointer ${styles ? styles : ''}`} href={href} target={external ? '_blank' : '_self'}>
            {text}
        </a>
    ) : null

}


export default SanityLink
