import React, { FunctionComponent, useEffect, useState } from 'react'
import { buildImageObj }                                 from '../../../../../gatsby/src/utilities/helpers'
import { imageUrlFor }                                   from '../../../../../gatsby/src/utilities/image-url'
import { MainImageComponentData }                        from '@open-law/open-law-shared'

interface MainImageProps {
    mainImage: MainImageComponentData
    width?: number
    fitImage?: boolean
    float?: boolean
    pushRight?: boolean
}

const MainImage: FunctionComponent<MainImageProps> = (props) => {

    const {
        mainImage,
        width = 1200,
        fitImage,
        float,
        pushRight }= props
    // console.log(mainImage)

    const [imgUrl, setImgUrl] = useState<string | null>()


    useEffect(() => {
            if (!fitImage && mainImage) {
                setImgUrl(
                    imageUrlFor(
                        buildImageObj(mainImage)
                    ).width(width)
                        // .height(Math.floor((9 / 16) * width))
                        .fit('crop')

                        .auto('format')
                        .url()
                )
            } else {
                setImgUrl(mainImage.asset.url)
            }
        }, [mainImage, width, fitImage]
    )


    return imgUrl ? (
        <figure className={'mt-6 mb-6'}>
            <img className={`rounded-lg ${float ? 'float-right' : ''} ${pushRight ? '-mr-96' : ''}`} src={imgUrl} alt={mainImage.alt || ''} />
            <figcaption className={'text-md text-black font-medium mt-2 text-right'}>
                {mainImage.caption}
            </figcaption>
        </figure>
    ) : <></>
}

export {MainImage}
