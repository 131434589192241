import React, { FunctionComponent } from 'react'
import {
    getInternalLinkReference,
    ProjectColours,
    SanityPreviewImage
}                                   from '../../index'
import {
    Feature,
    FeatureList as IFeatureList,
    HeadingAndLabel,
    Link
}                                   from '../entities/schema'
import { createComponentTheme }     from '../helpers'
import { PortableTextRenderer }     from './text/portableTextRenderer'

interface FeatureListProps {
    featureListData: IFeatureList
    projectColour: ProjectColours
}

const handleParseLink = (link: Link) => {
    if (link) {
        const {internal, external} = link
        if (external && typeof external === 'string') {
            return external
        } else if (internal && internal.slug && internal.slug.current) {
            return `/${internal.slug.current}`
        } else if (internal && (internal?._ref || internal?.route?._ref)) {
            getInternalLinkReference(internal?.route?._ref || internal._ref)
                .then((slug) => `/${slug}`)
        }
    }
    return ''
}

interface RenderLinkPassThruProps {
    featureLink: Link
    linkStyles?: string
    nonLinkStyles?: string
}

const RenderLinkPassThru: FunctionComponent<RenderLinkPassThruProps> = (props) => {

    const {featureLink, children, linkStyles, nonLinkStyles} = props


    return (
        <>
            {
                featureLink && featureLink?.external || featureLink?.internal ? (
                    <a className={`${linkStyles} cursor-pointer pt-6`} href={handleParseLink(featureLink)}>
                        {children}
                    </a>
                ) : (
                    <div className={nonLinkStyles}>
                        {children}
                    </div>
                )
            }
        </>
    )
}

const FeatureList: FunctionComponent<FeatureListProps> = (props) => {

    // console.log('Feature List Props: ', props)
    const projectColour = 'blue'
    const {}            = props
    const theme         = props.featureListData?.theme

    const {
              headingColour,
              textColour,
              highlightColour,
              primaryButtonColour,
              primaryButtonText,
              backgroundColour,
              hover,
              outline
          } = createComponentTheme(theme)

    const renderFeatures = (
        featureData: IFeatureList
    ) => {

        const {featureListLayout, features, heading} = featureData

        switch (featureListLayout) {
            case 'list': {
                return renderStacked(features)
            }
            case 'listWithTitle' : {
                return renderListWithTitle(features, heading)
            }
            case 'cards': {
                return renderCards(features)
            }
            default: {
                return renderStacked(features)
            }
        }
    }

    const renderHeadingBlock = (heading: HeadingAndLabel) => (
        <section
            className={`p-8 ${heading.headingBlockPosition === 'centre' ? 'flex justify-center text-center w-1/3' : ''}`}>
            {
                heading ? (
                    <section
                        className={`${textColour}`}>
                                    <span>
                                        {heading.preHeadingLabel}
                                    </span>
                        <h3 className="text-4xl font-bold">
                            {heading.headingText}
                        </h3>
                    </section>
                ) : null
            }
        </section>
    )

    const renderListWithTitle = (feats: Feature[], heading: HeadingAndLabel) => {
        return (
            <>
                {
                    heading?.headingBlockPosition === 'centre' ||
                    (!heading?.headingBlockPosition && heading?.headingText) ? (
                        renderHeadingBlock(heading)
                    ) : null
                }
                <section
                    className={`grid ${heading?.headingBlockPosition === 'centre' ? 'grid-cols-1' : 'grid-cols-2'} gap-2 justify-center`}>
                    {
                        heading?.headingBlockPosition === 'left' ? renderHeadingBlock(heading) : null
                    }
                    <section className="w-2/3 grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
                        {
                            feats.map(
                                (feature, idx) => renderFeature(feature, idx)
                            )
                        }
                    </section>
                    {
                        heading?.headingBlockPosition === 'right' ? renderHeadingBlock(heading) : null
                    }
                </section>

            </>
        )
    }


    const renderCards = (feats: Feature[]) => {
        return (
            <section className="max-w-7xl mx-auto py-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 ">
                    {
                        feats ? feats.map((feature) => (
                                <RenderLinkPassThru key={feature.featureTitle} featureLink={feature.featureLink}
                                                    nonLinkStyles={'cursor-auto pt-6'}
                                                    linkStyles={'cursor-pointer pt-6'}>
                                    <div
                                        className={`flow-root ${feature.featureLink ? 'hover:bg-gray-200' : ''} bg-gray-50 rounded-lg px-6 pb-8`}>
                                        <div className="-mt-6">
                                            <div className={'flex justify-center'}>
                                                <div className="inline-flex items-center justify-center p-2 bg-white rounded-md
                                                shadow-lg">
                                                    {
                                                        feature.featureIcon ? (
                                                            <SanityPreviewImage imageRef={feature.featureIcon}
                                                                                imageStyles={'h-12 w-12 text-white'}/>
                                                        ) : (
                                                            <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg"
                                                                 fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      strokeWidth="2"
                                                                      d="M9 5l7 7-7 7"/>
                                                            </svg>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <h3 className={`mt-8 text-lg font-bold text-gray-900 tracking-tight text-center`}>{feature.featureTitle}</h3>
                                            <div className={'mt-8 text-center text-gray-500'}>
                                                <PortableTextRenderer blocks={feature.featureText}
                                                                      projectColour={projectColour}/>
                                            </div>
                                        </div>
                                    </div>
                                </RenderLinkPassThru>
                            )
                        ) : null
                    }
                </div>
            </section>
        )
    }

    const renderStacked = (feats: Feature[]) => (
        <section className="py-4">
            {
                feats.map(
                    (feature, idx) => renderFeature(feature, idx)
                )
            }
        </section>
    )

    const renderFeature = (feature: Feature, idx: number) => (
        <dl key={`${feature.featureTitle}-${idx}`}
            className={`pt-10 pb-10 p-1 space-y-10 ${feature.featureLink ? 'hover:bg-gray-200' : ''} `}>
            <RenderLinkPassThru featureLink={feature.featureLink} linkStyles={'cursor-pointer flex'}
                                nonLinkStyles={'cursor-auto flex'}>
                <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md text-black">
                            {
                                feature.featureIcon ? (
                                    <SanityPreviewImage imageRef={feature.featureIcon}/>
                                ) : (
                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M9 5l7 7-7 7"/>
                                    </svg>
                                )
                            }
                        </div>
                    </div>
                    <div className="ml-8">
                        {
                            feature.featureTitle && (
                                                     <dt className={`text-lg leading-6 font-medium ${headingColour}`}>
                                                         {feature.featureTitle}
                                                     </dt>
                                                 )
                        }
                        <dd className={`mt-2 text-base font-normal ${textColour}`}>
                            <PortableTextRenderer blocks={feature.featureText} projectColour={projectColour}/>
                        </dd>
                    </div>
                </div>
            </RenderLinkPassThru>
        </dl>
    )

    return (
        <section className={`px-4 ${backgroundColour}`}>
            {
                props.featureListData && renderFeatures(props.featureListData)
            }
        </section>
    )
}

export { FeatureList }
