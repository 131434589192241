import React, { FunctionComponent } from 'react'
import { CallToAction }             from '../entities/schema'
import { createComponentTheme }     from '../helpers'
import SanityLink                   from './text/Link'
import { PortableTextRenderer }     from './text/portableTextRenderer'

interface CTAProps {
    cta: CallToAction
}

const CTA: FunctionComponent<CTAProps> = (props) => {
    const {secondaryText, leadText, longText, button, layout, theme, title} = props.cta
    const {
              textColour,
              highlightColour,
              primaryButtonColour,
              primaryButtonText,
              backgroundColour,
              headingColour,
              hover,
              outline
          }                                                                 = createComponentTheme(theme)
    switch (layout) {
        case 'textButtonStackedCentred': {
            return (
                <div className={`${backgroundColour}`}>
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className={`font-medium text-3xl font-extrabold ${headingColour} sm:text-4xl`}>
                            <span className="block">{leadText}</span>
                        </h2>
                        {
                            secondaryText && secondaryText.split('\n')
                                              .map((text, idx) => (
                                                  <p key={idx}
                                                     className={`font-bold mt-2 text-xl leading-6 ${highlightColour}`}>{text}</p>
                                              ))
                        }
                        {
                            longText ? (
                                <section className={'py-6'}>
                                    <PortableTextRenderer blocks={longText}
                                                          projectColour={textColour}
                                                          className={`portable-text`}/>

                                </section>
                            ) : null
                        }

                        {
                            button && button.url ? (
                                <SanityLink node={button.url} text={button.buttonText}
                                            styles={`inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md ${primaryButtonText} ${primaryButtonColour} hover:${hover}`}/>
                            ) : null
                        }
                    </div>
                </div>
            )
        }
        case 'textLeftButtonRight': {
            return (
                <div className={`${backgroundColour}`}>
                    <div
                        className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className={`text-3xl font-extrabold tracking-tight ${headingColour} md:text-4xl`}>
                            <span className="block">{leadText}</span>
                            {
                                secondaryText && secondaryText.split('\n')
                                                  .map((text, idx) => (
                                                      <span key={idx}
                                                            className={`block font-medium text-xl ${highlightColour}`}>{secondaryText}</span>
                                                  ))
                            }
                        </h2>
                        {
                            longText ? (
                                <section className={'py-6'}>
                                    <PortableTextRenderer blocks={longText}
                                                          projectColour={textColour}
                                                          className={`portable-text`}/>
                                </section>
                            ) : null
                        }
                        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                            <div className="inline-flex rounded-md shadow">

                                {
                                    button && button.url ? (
                                        <SanityLink node={button.url} text={button.buttonText}
                                                    styles={`inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md ${primaryButtonText} ${primaryButtonColour} hover:${hover}`}/>
                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        default: {
            return null
        }
    }
}

export { CTA }
