import SanityClient from '@sanity/client'
import React, {
    FunctionComponent,
    useEffect,
    useState
}                   from 'react'

interface ImageProps {
    imageRef: { asset: { _ref: string, url: string } }
    imageStyles: string
    heightScaling?: number
}

const SanityPreviewImage: FunctionComponent<ImageProps> = (props) => {
    const {imageRef, heightScaling, imageStyles = ''} = props
    const [image, setImage]            = useState('')

    // console.log('Sanity image preview', props)
    useEffect(() => {
        if (imageRef?.asset?.url) {
            if (heightScaling) {
                setImage(`${imageRef.asset.url}?h=${heightScaling}&fm=webp`)
            } else {
                setImage(imageRef.asset.url)
            }
        } else if (imageRef?.asset?._ref) {
            const client = SanityClient({
                projectId: '81k3cw4t',
                dataset  : 'production',
                useCdn   : true
            })
            const query  = `*[_id == "${imageRef.asset._ref}"]`
            client.fetch(query, {})
                .then((result) => {
                    if (heightScaling) {
                        setImage(`${result[0].url}?h=${heightScaling}&fm=webp`)
                    } else {
                        setImage(result[0].url)
                    }
                })
                .catch(() => setImage(''))
        } else {
            setImage('')
        }
    }, [imageRef])

    return image ? (
        <img className={imageStyles} src={image} alt={'Feature icon'}/>
    ) : null
}

export { SanityPreviewImage }
