import {
    Button,
    Hero as IHero,
    MainImage
}                                   from 'open-law-cms/schema'
import React, { FunctionComponent } from 'react'
import { createComponentTheme }     from '../helpers/tailwind-color.helpers'
import HeroImage                    from './HeroImage'
import SanityLink                   from './text/Link'
import { PortableTextRenderer }     from './text/portableTextRenderer'


interface HeroProps {
    node: IHero
}

const Hero: FunctionComponent<HeroProps> = (props) => {
    const {
              buttons,
              heading,
              theme = 'green|white|white|white',
              heroContent,
              heroImage,
              heroImagePosition,
              preHeadingLabel
          } = props.node

    const {
              headingColour,
              textColour,
              highlightColour,
              primaryButtonColour,
              primaryButtonText,
              backgroundColour,
              hover,
              outline
          } = createComponentTheme(theme)

    const renderButton = (button: Button, index: number) => (
        <div key={index}
             className={`mt-3 rounded-md shadow sm:mt-0 ${index === 1 && 'sm:ml-3'} ${index % 2 === 0 ? primaryButtonColour : null}`}>
            <div
                className={`cursor-pointer align-middle w-full flex items-center justify-center px-8 py-3 border ${index % 2 === 0 ? 'border-transparent font-bold' : (backgroundColour === 'bg-theme-grey-light' || backgroundColour === 'bg-white') ? `${outline}` : 'border-white'} text-base ${index % 2 === 0 ? primaryButtonText : textColour} font-medium rounded-md md:py-4 md:text-lg md:px-10`}>
                <SanityLink node={button.url} text={button.buttonText}/>
            </div>
        </div>
    )

    const setStylesForHeadingArea = (image: MainImage, imagePosition: 'left' | 'right') => {
        if (image) {
            return imagePosition === 'left' ? 'xl:pl-16' : 'xl:pr-16'
        }
        return 'xl:pr-16'
    }

    const setJustificationForHeadingArea = (imagePosition: 'left' | 'right' = 'left') => {
        return imagePosition === 'left' ? 'justify-end' : 'justify-start'
    }

    return (
        <section className={`lg:relative ${backgroundColour}`}>
            {
                heroImage && heroImagePosition === 'left' ? (

                    <div
                        className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 lg:h-full">
                        <HeroImage imageRef={heroImage}/>
                    </div>
                ) : null
            }
            <div
                className={`mx-auto max-w-7xl w-full pt-16 pb-20 justify-center lg:justify-start lg:py-48 lg:text-left flex ${setJustificationForHeadingArea(heroImagePosition)}`}>
                <div className={`px-4 lg:w-1/2 sm:px-8 ${setStylesForHeadingArea(heroImage, heroImagePosition)}`}>
                    <div className={`text-sm mb-2 font-bold ${highlightColour}`}>
                        {preHeadingLabel}
                    </div>
                    <h1 className={`text-4xl tracking-tight font-extrabold ${headingColour} sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl`}>
                        <span className="block xl:inline">{heading}</span>
                    </h1>
                    <div
                        className={`mt-3 max-w-md mx-auto text-lg ${textColour} sm:text-xl md:mt-5 md:max-w-3xl`}>
                        <PortableTextRenderer projectColour={textColour} blocks={heroContent}/>
                    </div>
                    <div className={`mt-10 sm:flex sm:justify-center lg:justify-start`}>
                        {
                            buttons && buttons.map((b, index) => renderButton(b, index))
                        }
                    </div>
                </div>
            </div>
            {
                heroImagePosition !== 'left' ? (
                    <div
                        className="relative w-full h-64 sm:h-72 sm:hidden lg:block md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                        {
                            heroImage && heroImagePosition === 'right' ? (
                                <HeroImage imageRef={heroImage}/>
                            ) : null
                        }
                    </div>
                ) : null
            }
        </section>
    )
}

export { Hero }
