import React, { FunctionComponent }  from 'react'
import { TitleBlock as ITitleBlock } from '../entities/schema'
import { createComponentTheme }      from '../helpers/index'

interface HeadingBlockProps {
    data: ITitleBlock
    fullWidth?: boolean
}

const TitleBlock: FunctionComponent<HeadingBlockProps> = (props) => {

    // console.log('Title block props', props)
    const {fullWidth = false}                                       = props
    const {colourTheme, blurb, headingText, preHeadingLabel, theme} = props.data
    const {
              backgroundColour,
              headingColour,
              highlightColour,
              outline,
              primaryButtonColour,
              textColour,
              primaryButtonText
          }                                                         = createComponentTheme(theme)
    const renderFullWidthTitleBlock                                 = () => {

        return (
            <div className={`${backgroundColour}`}>
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-18 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <p className={`${highlightColour} text-base font-semibold tracking-wide uppercase`}>{preHeadingLabel}</p>
                        <h2 className={`mt-1 text-4xl font-extrabold ${headingColour} sm:text-5xl sm:tracking-tight lg:text-6xl`}>
                            {headingText}
                        </h2>
                        {
                            blurb && blurb.split('\n')
                                      .map(
                                          (text) => (
                                              <p className={`max-w-xl mt-5 mx-auto text-xl ${textColour}`}>{text}</p>
                                          )
                                      )
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderRichTextTitleBlock = () => {
        return (
            <section className={`${backgroundColour} text-center py-10`}>
                {
                    preHeadingLabel ? (
                        <p className={`text-2xl font-semibold ${highlightColour}`}>
                            {preHeadingLabel}
                        </p>
                    ) : null
                }
                <h1 className={`${headingColour} text-4xl my-2 text-black font-semibold`}>
                    {headingText}
                </h1>
                {
                    blurb ? (
                        <section>
                            {
                                blurb.split('\n')
                                    .map(
                                        (text) => (
                                            <p className={`${textColour}`}>{text}</p>
                                        )
                                    )
                            }
                        </section>
                    ) : null
                }
            </section>
        )
    }
    return fullWidth ? renderFullWidthTitleBlock() : renderRichTextTitleBlock()
}

export { TitleBlock }
