import {Slug} from '@open-law/open-law-shared'

export type SanityLinkWithRefs = {
    _type: 'link';
    external?: string;

    internal?: { slug: Slug }

    openInNewWindow?: boolean;
};
export const SanityLinkHelper: (linkObject: SanityLinkWithRefs) => string = (linkObject) => {
    if (linkObject.external) {
        return linkObject.external
    } else if (linkObject.internal?.slug?.current) {
        return `/${linkObject.internal?.slug?.current}`
    }
    return '#'
}
