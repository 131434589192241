import { Author }                   from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'

interface StaffProfilesProps {
    staffProfilesComponentData: { profiles: Author[], heading: string, blurb: string }

}

const StaffProfiles: FunctionComponent<StaffProfilesProps> = (props) => {

    const {profiles, heading, blurb} = props.staffProfilesComponentData

    const renderStaffProfile = (staffMember: Author) => {
        return (
            <li key={staffMember._id} className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">

                        {
                            staffMember.image &&
                            staffMember.image.asset &&
                            staffMember.image.asset.url
                            && <img className="object-cover shadow-lg rounded-lg"
                                    src={staffMember.image.asset.url}
                                    alt={staffMember.image.alt}/>
                        }
                    </div>
                    <div className="sm:col-span-2">
                        <div className="space-y-4">
                            <div className="text-lg leading-6 font-medium space-y-1">
                                <h3 className={'text-black'}>{staffMember.name}</h3>
                                <p className="text-blue-600">{staffMember.jobTitle}</p>
                            </div>
                            <div className="text-lg text-black">
                                {staffMember.bio && staffMember.bio.split('\n')
                                    .map((text, idx) => <p key={idx}
                                                           className="text-gray-500">{text}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <section>
            <div className="bg-white">
                <div className="mx-auto py-12 max-w-7xl">
                    <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                        {
                            heading && (
                                        <div className="space-y-5 sm:space-y-4">
                                            <h2 className="text-3xl text-black font-extrabold tracking-tight sm:text-4xl">{heading}</h2>
                                            <p className="text-xl text-gray-500">{blurb}</p>
                                        </div>
                                    )
                        }
                        <div className="lg:col-span-3">
                            <ul style={{listStyleType: 'none'}}
                                className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0 list-none">
                                {
                                    profiles && profiles.map((staffMember) => renderStaffProfile(staffMember))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export { StaffProfiles }
