import { ProjectColours } from '@open-law/open-law-shared'
import { ComponentTheme } from '../entities/schema'

interface themeDetails {
    backgroundColour: string
    headingColour: string
    highlightColour: string
    textColour: string
    primaryButtonColour: string
    outline: string
    primaryButtonText: string
    hover: string
}

const createComponentTheme: (theme: ComponentTheme | { theme: string }) => themeDetails = (theme) => {
    if (theme && theme.theme) {
        // console.log('Theme:', theme.theme)
        const styleInfo = theme.theme.split('|')
        if (styleInfo.length === 4) {
            const background: ProjectColours = styleInfo[0] as ProjectColours
            const heading: ProjectColours    = styleInfo[1] as ProjectColours
            const highlight: ProjectColours  = styleInfo[2] as ProjectColours
            const text: ProjectColours       = styleInfo[3] as ProjectColours

            const resultingTheme = {
                backgroundColour   : setBackgroundColour(background),
                headingColour      : setTextColour(heading),
                highlightColour    : setTextColour(highlight),
                textColour         : setTextColour(text),
                primaryButtonColour: setBackgroundColour(highlight),
                primaryButtonText  : setTextColour(background),
                outline            : setOutlineColour(highlight),
                hover              : setHoverColour(highlight)
            }

            if (background === 'dark-blue') {
                resultingTheme.primaryButtonColour = 'bg-theme-blue'
                resultingTheme.highlightColour     = 'text-white'
                resultingTheme.primaryButtonText   = 'text-white'
            }
            if (background === 'dark-grey') {
                resultingTheme.primaryButtonText = 'text-white'
            }
            if (background === 'dark-grey' && resultingTheme.primaryButtonColour === 'bg-heading-green') {
                resultingTheme.primaryButtonColour = 'bg-white'
                resultingTheme.highlightColour     = 'text-white'
            }
            if (background === 'dark-grey' && highlight === 'dark-green') {
                resultingTheme.highlightColour     = setTextColour('white')
                resultingTheme.primaryButtonText   = setTextColour('white')
                resultingTheme.primaryButtonColour = 'bg-theme-green'
            }
            if (highlight === 'purple') {
                resultingTheme.primaryButtonColour = 'bg-theme-purple-light'
                resultingTheme.highlightColour     = 'text-theme-purple-light'
            }
            // console.log('Resulting Theme: ', resultingTheme)
            return resultingTheme
        }
    }
    return {
        backgroundColour   : 'bg-white',
        headingColour      : 'text-black',
        highlightColour    : 'text-blue',
        textColour         : 'text-black',
        primaryButtonColour: 'bg-theme-blue',
        primaryButtonText  : 'text-white',
        outline            : 'border-theme-blue',
        hover              : 'hover:bg-theme-blue'
    }
}

const getValueFromTheme = (theme: ComponentTheme, target: 'backgroundColour' | 'textColour') => {
    const styleInfo                  = theme.theme.split('|')
    const mode: ProjectColours       = styleInfo[0] as ProjectColours
    const background: ProjectColours = styleInfo[1] as ProjectColours
    const textColour: ProjectColours = styleInfo[2] as ProjectColours
    switch (target) {
        case 'backgroundColour':
            return background
        case 'textColour':
        default:
            return ''
    }
}

const setBackgroundColour = (themeBackground: ProjectColours) => {
    switch (themeBackground) {
        case 'blue':
            return 'bg-theme-blue'
        case 'orange':
            return 'bg-orange-500'
        case 'dark-blue':
            return 'bg-theme-blue-darkest'
        case 'red':
            return 'bg-theme-red'
        case 'green':
            return 'bg-theme-green'
        case 'grey':
            return 'bg-theme-grey-light'
        case 'dark-grey':
            return 'bg-theme-grey'
        case 'dark-green':
            return 'bg-theme-dark-green'
        case 'purple':
            return 'bg-theme-purple'
        case 'teal':
            return 'bg-theme-teal'
        case 'black':
            return 'bg-black'
        case 'white':
            return 'bg-white'
        default:
            return 'bg-white'
    }
}

const setHoverColour = (themeBackground: ProjectColours) => {
    switch (themeBackground) {
        case 'blue':
            return 'bg-theme-blue-light'
        case 'orange':
            return 'bg-orange-500'
        case 'dark-blue':
            return 'bg-theme-blue'
        case 'red':
            return 'bg-theme-red-light'
        case 'green':
            return 'bg-theme-green-light'
        case 'grey':
            return 'bg-white'
        case 'dark-grey':
            return 'bg-theme-grey-light'
        case 'dark-green':
            return 'bg-theme-green-light'
        case 'purple':
            return 'bg-theme-purple'
        case 'teal':
            return 'bg-theme-teal-light'
        case 'black':
            return 'bg-theme-dark-grey'
        case 'white':
            return 'bg-theme-grey-light'
        default:
            return 'bg-white'
    }
}

const setTextColour = (textColour: ProjectColours) => {
    switch (textColour) {
        case 'grey':
            return 'text-theme-grey-light'
        case 'dark-grey':
            return 'text-theme-gray'
        case 'dark-blue':
            return 'text-theme-blue-darkest'
        case 'white':
            return 'text-white'
        case 'blue':
            return 'text-theme-blue'
        case 'teal':
            return 'text-theme-teal'
        case 'red':
            return 'text-theme-red'
        case 'dark-green':
            return 'text-theme-dark-green'
        case 'green':
            return 'text-theme-green'
        case 'orange':
            return 'text-theme-orange'
        case 'purple':
            return 'text-theme-purple'
        case 'black':
            return 'text-black'
        default:
            return 'text-black'
    }
}

const setOutlineColour = (themeBackground: ProjectColours) => {
    switch (themeBackground) {
        case 'blue':
            return 'border-theme-blue'
        case 'orange':
            return 'border-orange-500'
        case 'dark-blue':
            return 'border-theme-blue-darkest'
        case 'red':
            return 'border-theme-red'
        case 'green':
            return 'border-theme-green'
        case 'grey':
            return 'border-theme-grey-light'
        case 'dark-grey':
            return 'border-theme-grey'
        case 'dark-green':
            return 'border-theme-dark-green'
        case 'purple':
            return 'border-theme-purple'
        case 'teal':
            return 'border-theme-teal'
        case 'black':
            return 'border-black'
        case 'white':
            return 'border-white'
        default:
            return 'border-white'
    }
}

export {
    createComponentTheme,
    setTextColour,
    setBackgroundColour
}
