import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import React, {
    FunctionComponent,
    MouseEvent
} from 'react'
import {
    utcTimeStringtoDateWords,
    utcTimeStringtoTimeWords,
    utcTimeStringtoTimeZone
} from '../../../../../gatsby/src/utilities/helpers'
import {
    EventSelector,
    MainImage,
    TrainingEvent
} from '../entities/schema'
import { SanityPreviewImage } from './SanityPreviewImage'
import SanityLink from './text/Link'
import './EventsListInRichText.css'

declare const _paq: any[]

interface RawChildren {
    _key: string
    _type: string
    marks: any[]
    text: string
}

interface Excerpt {
    _key: string
    _type: string
    style: string
    list?: any
    _rawChildren: RawChildren[]
}

export interface OpenLawEvent { // Called OpenLawEvent to avoid a collision with a type that is part of the DOM
    startDateTime: string
    title: string
    tags: string[]
    registrationUrl: string
    platform: string
    mainImage: MainImage
    endDateTime: string
    duration: number
    publishedAt: string
    excerpt: Excerpt[]

    body?: any
    slug: { current: string }
    // remember you can use the JSON to TS interface conversion tool.
}


interface EventListProps {
    data: EventSelector
}

const EventsList: FunctionComponent<EventListProps> = (props) => {
    const events: TrainingEvent[] = props.data.eventList

    const handleNotifyUsage = (category: string, action: string, eventName: string) => {
        if (_paq) {
            _paq.push(['trackEvent', category, action, eventName])
        }
    }
    const handleShowTypeformPopup = (clickEvent: MouseEvent<HTMLButtonElement>, event: TrainingEvent) => {
        const popup = createPopup(event.registrationId)
        popup.open()
        handleNotifyUsage(
            'Training Events',
            'click on register button',
            `Event Registration Form Visit: ${event.title} | ${event.registrationId}}`)
    }

    const renderEventItem = (event: TrainingEvent) => {
        if (
            Date.parse(event.startDateTime) > Date.now()
            && Date.parse(event.publishedAt) < Date.now()
        ) {
            return (
                <section className="flex w-[300px] justify-center">
                    <section className="w-full event-item-grid items-emaeen border-2 border-gray-200 shadow rounded">
                        {/*  IMAGE */}
                        <div className="justify-center items-stretch hidden sm:flex">
                            <SanityPreviewImage imageRef={event.mainImage} imageStyles={''} />
                        </div>
                        {/* TITLE */}
                        <div className="w-full p-6">
                            <div className="">
                                <h2 className="text-xl text-black font-medium">
                                    {event.title}
                                </h2>
                            </div>
                        </div>
                        {/*  DETAILS */}
                        <p className="pt-4 px-6">
                            <span className="text-xl font-light">
                                {
                                    utcTimeStringtoTimeWords(event.startDateTime)
                                }
                            </span>
                            <br />
                            <span>
                                {
                                    utcTimeStringtoDateWords(event.startDateTime)
                                }
                            </span>
                            <br />
                            <span className="font-light">
                                {
                                    utcTimeStringtoTimeZone(event.startDateTime)
                                }
                            </span>
                        </p>
                        {/* BUTTONS */}
                        <div className="flex flex-row items-end justify-end gap-4 p-6 pt-0">
                            {
                                event.registrationId ? (
                                    <a id="registerLink" className="px-4 py-2 bg-green-500 text-white text-sm rounded cursor-pointer" href={event.registrationId} target="_blank">Register</a>
                                    // <button type={'button'}
                                    //     className="px-4 py-2 bg-green-500 text-white text-sm rounded cursor-pointer"
                                    //     onClick={(e) => handleShowTypeformPopup(e, event)}>
                                    //     Register
                                    // </button>
                                ) : null
                            }
                            <a className="px-4 py-2 text-regular bg-green-500 text-white text-sm rounded">
                                {
                                    event?.eventInfoPageButton?.url ? (
                                        <SanityLink node={event.eventInfoPageButton.url}
                                            text={event.eventInfoPageButton.buttonText} />
                                    ) : null
                                }
                            </a>
                        </div>
                    </section>
                </section>
            )
        }
        return null
    }

    return (
        <section className="relative mx-auto max-w-7xl">
            {
                events &&
                    events.length >= 1 ? (
                    <section className="flex justify-center flex-wrap gap-2.5">
                        {
                            events
                                .map(
                                    (event) =>
                                        event.publishedAt
                                            ? renderEventItem(event)
                                            : null
                                )
                        }
                    </section>
                ) : (
                    <p>
                        No events, check back soon.
                    </p>
                )
            }
        </section>
    )
}

export default EventsList
