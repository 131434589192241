import {
    Author,
    WebinarPart
} from '@open-law/open-law-shared'
import SanityClient from '@sanity/client'
import React, {
    FunctionComponent,
    useEffect,
    useState
} from 'react'
import {SanityReference} from 'sanity-codegen'
import {Webinar} from '../entities/schema'
import {SanityPreviewImage} from './SanityPreviewImage'

interface ProjectListProps {
    webinars: Webinar[] | SanityReference<Webinar>[]
    preview?: boolean
}

const WebinarList: FunctionComponent<ProjectListProps> = (props) => {
    // console.log('Webinar List props: ', props)
    const preview = props.preview
    const [webinars, setWebinars] = useState<Webinar[]>()

    useEffect(() => {
        // ON CMS PREVIEW:
        if (preview) {
            if (props.webinars && props.webinars.length > 0) {

                setWebinars([])
                const data: Webinar[] | SanityReference<Webinar>[] = props.webinars

                const client = SanityClient({
                    projectId: '81k3cw4t',
                    dataset: 'production',
                    useCdn: true
                })

                const webinarData: Webinar[] = []

                data.forEach((item) => {
                    if (item._ref) {
                        const query = `*[_type == "webinar" && _id == "${item._ref}"]`
                        client.fetch(query, {})
                            .then((result) => {
                                webinarData.push(result[0])
                            })
                    } else {
                        webinarData.push(item)
                    }
                })
                setWebinars(webinarData)
            }
        } else {
            setWebinars(props.webinars as Webinar[])
        }
    }, [props.webinars, props.preview])

    const renderProjectList = (webinar: Webinar) => {
        return webinar && (
            <div key={webinar.title} className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                            {webinar.title}
                        </h2>
                        {
                            webinar.blurb && webinar.blurb.split('\n')
                                .map((line, idx) => (
                                    <p key={idx} className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                        {line}
                                    </p>
                                ))
                        }
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {
                            webinar && webinar.parts.map((project) => project.video && project.video.videoUrl ? renderProjectItem(webinar, project) : null)
                        }
                    </div>
                </div>
            </div>
        )
    }

    const buttonStyles = `text-base font-semibold text-blue-600 hover:text-blue-500 mt-3 `

    const renderProjectItem = (webinar: Webinar, part: WebinarPart) => {
        return (
            <div key={part.title}
                 className="group flex flex-col rounded-lg shadow-lg ">
                <a href={`/webinars/${webinar.slug.current}/${part && part.slug && part.slug.current ? part.slug.current : ''}`}
                   className="block mt-2">
                    <div className="">
                        <SanityPreviewImage imageRef={part.thumbnail}
                                            imageStyles={'h-48 w-full object-cover'} heightScaling={300}/>
                    </div>
                </a>

                <div className="group-hover:bg-gray-100 flex-1 bg-white p-6 flex flex-col justify-between">

                    <div className="flex-1 flex justify-start">
                        <a href={`/webinars/${webinar.slug.current}/${part && part.slug && part.slug.current ? part.slug.current : ''}`}
                           className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">
                                {part.title}
                            </p>
                            <div className="mt-3 text-base text-gray-500">
                                {
                                    part.partBlurb && part.partBlurb.split('\n')
                                        .map((line, idx) => (
                                            <p key={idx} className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                                {line}
                                            </p>
                                        ))
                                }
                            </div>
                        </a>
                    </div>
                    {/* {
                     webinar.authors && webinar.authors.map((author) => renderProjectLead(author))
                     }*/}
                </div>
            </div>
        )
    }

    const renderProjectLead = (projectLead: Author) => (
        <>
            <div className="mt-3 flex">
                <p className="text-sm font-medium text-gray-500">
                    Instructor
                </p>
            </div>
            <div className="mt-2 flex items-center">
                <div className="flex-shrink-0">
                    <a href="#">
                        <span className="sr-only">{projectLead.name}</span>
                        <SanityPreviewImage imageRef={projectLead.image}
                                            imageStyles={'h-10 w-10 rounded-full'}/>
                    </a>
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                        <a href="#" className="hover:underline">
                            {projectLead.name}
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
    return (
        <section>
            {
                webinars && webinars.map((webinar) => renderProjectList(webinar))
            }
        </section>
    )
}

export {WebinarList}


