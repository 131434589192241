import React, {FunctionComponent, useEffect, useState} from 'react'
import {Image}                                         from '@sanity/types'
import SanityClient                                    from '@sanity/client'
import {MainImage}                                     from 'open-law-cms/schema'

interface HeroImageProps {
    imageRef: MainImage
}

const HeroImage: FunctionComponent<HeroImageProps> = (props) => {
    const {imageRef} = props

    const [image, setImage] = useState<Image>()

    useEffect(() => {
        // ON CMS PREVIEW:
        if (!imageRef.asset?.url) {
            const client = SanityClient({
                projectId: '81k3cw4t',
                dataset: 'production',
                useCdn: true
            })
            const query = `*[_id == "${imageRef.asset._ref}"]`
            client.fetch(query, {})
                .then((result) => {
                    // console.log('Image fetched: ', result)
                    setImage(result[0])
                })
        } else { // FOR GATSBY:
            setImage(imageRef.asset)
        }

    }, [imageRef])

    return image && image.url ? (
        <img className={`absolute inset-0 w-full h-full object-cover`} src={image.url} alt=""/>
    ) : null
}

export default HeroImage
